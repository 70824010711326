import React from 'react';

function Footer() {
  return (
    <div className="Footer flex-initial p-6 lg:px-8">
        <span>&copy; 2023 Nicholas Paolucci</span>
    </div>
  );
}

export default Footer;